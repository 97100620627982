import React, { FC, PropsWithChildren } from "react"
import cc from "classcat"

const Animated: FC<PropsWithChildren<{
  delay?: string
  duration?: string
}>> = ({
  children,
  duration,
  delay,
}) => {
  const style = {
    animationDelay: delay || 0,
    animationDuration: duration || '1s',
  } as React.CSSProperties

  return (
    <div className={cc([
      "animate__animated animate__fadeInUp",
      delay && `animate__delay`
    ])} style={style}>
      {children}
    </div>
  )
}

export default Animated
