import React from "react"
import Box from "../Box"
import Card from "../Card"
// import AOS from "aos"
import "./index.css"

export default React.memo((props: {
  image: string | React.ReactNode
  name: string | React.ReactNode
  description: string | React.ReactNode
  position: string | React.ReactNode
  animatedimage?: string
}) => {
  const {
    image,
    animatedimage,
    name,
    description,
    position,
  } = props

  React.useEffect(() => {
    //AOS.refresh()
  })

  return (
    <Box
      className="card-team__container"
      background={<div className="card-team__image">
        {image}
        {animatedimage && (<img src={animatedimage} />)}
      </div>}
    >
      <Card
        className="team-card"
      >
        <header className="card-team__title">
          <h4>{name}</h4>
        </header>

        <section className="card-team__description is-hidden-xs">
          <p>{description}</p>
        </section>

        <footer>{position}</footer>
      </Card>
    </Box>
  )
})
