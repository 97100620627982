import React, { useContext } from "react"
import { Helmet } from "react-helmet"
import { PageProps, graphql } from "gatsby"
import Grid from "~/components/Grid/Auto"
import Card from "~/components/Team"
import useScrollToTop from "~/hooks/useScrollToTop"
import decode from "~/functions/decode"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import useMaxWidth from "~/hooks/useMaxWidth"
import Animated from "~/components/Animated"
import Picture from "~/components/Picture"
import { I18n, I18nContext } from "~/components/I18n"

export const query = graphql`{
  site {
    siteMetadata {
      title
      description
      author
      image
      siteUrl
    }
  }
  wpMenuItem(url: {regex: "/^\\/team/"}) {
    id
    label
  }
  allWpColaborator(
    sort: {
      order: ASC,
      fields: menuOrder
    }
    filter: {
      status: {
        in: ["publish", "future"]
      }
    }
  ) {
    edges {
      node {
        id
        title
        excerpt
        posiO {
          position
        }
        gifAnimado {
          animatedImage {
            sourceUrl
          }
        }
        translations {
          enPosition
          enDescription
        }
        featuredImage {
          node {
            sourceUrl
            srcSet
            sizes
            mimeType
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1200, height: 1200, quality: 92, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
    distinct(field: id)
  }
}
`

export default ({
  data: {
    allWpColaborator,
    wpMenuItem: menuItem,
    site: {
      siteMetadata: {
        title,
        description,
        image,
        author,
        siteUrl
      },
    },
  },
}: PageProps<{
  site: {
    siteMetadata: {
      title: string
      description: string
      image: string
      author: string
      siteUrl: string
      apiUrl: string
    }
  }
  wpMenuItem?: {
    id: string
    label: string
  },
  allWpColaborator: {
    edges: {
      node: {
        id: string
        title: string
        excerpt: string
        posiO: {
          position: string
        }
        gifAnimado: {
          animatedImage: {
            sourceUrl: string
          }
        }
        translations: {
          enPosition: string
          enDescription: string
        }
        featuredImage: {
          node: {
            sourceUrl: string
            srcSet: string
            sizes: string
            mimeType: string
            localFile: {
              childImageSharp: {
                gatsbyImageData: IGatsbyImageData
              }
            }
          }
        }
      }
    }[]
  }
}>) => {
  useScrollToTop()
  useMaxWidth()

  const { locale } = useContext(I18nContext)

  return <>
    <Helmet
      defer={false}
      htmlAttributes={{
        lang: locale,
        dir: "ltr",
        prefix: "og: http://ogp.me/ns#",
      }}
    >
      <title>{title}</title>
      <link rel="author" href="/humans.txt" />

      <meta name="description" content={description} />
      <meta name="image" content={`${siteUrl}${image}`} />

      <meta property="og:title" content={title} />
      <meta property="og:locale" content={locale} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={`${siteUrl}${image}`} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={author} />
      <meta name="twitter:creator" content={author} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={`${siteUrl}${image}`} />


    </Helmet>

    <header>
      <h1>{menuItem?.label || "Our Fire Team"}</h1>
    </header>

    <p>
      <I18n
        pt="Somos o elo de ligação entre marcas e pessoas, o nosso
        propósito é comunicar destacando a personalidade de
        cada marca."
        en="We are the bond between brands and people, our purpose is to communicate highlighting the personality of each brand."
      />
      <br />
      <I18n
        pt="Dispomos de uma equipa multidisciplinar, com
        profissionais experientes em cada uma das suas áreas,
        atentos à necessidade do nosso cliente desenvolvemos
        uma solução 360º desde a conceção à implementação."
        en="We have a multidisciplinary team, with experienced professionals in each of their areas, attentive to our client's needs, we develop a 360º solution from conception to implementation."
      />
    </p>
    <p>
      <I18n
        pt="A paixão pelo que fazemos é o que nos une."
        en="The passion for what we do is what unites us."
      />
    </p>

    <section>
      <Grid className="has-margin-top has-no-gaps is-square">
        {allWpColaborator.edges
          .map(({
            node: {
              id, title, excerpt, featuredImage, posiO: { position },
              gifAnimado: { animatedImage },
              translations: {
                enPosition,
                enDescription,
              }
            }
          }, i) => {
            return (
              <Animated
                key={id}
                duration={`500ms`}
                delay={`${150 * i}ms`}
              >
                <Card
                  image={featuredImage && (
                    featuredImage.node.localFile?.childImageSharp?.gatsbyImageData ? (
                      <GatsbyImage
                        image={featuredImage.node.localFile.childImageSharp.gatsbyImageData}
                        alt={decode(title)}
                      />
                    ) : (
                      <div className="is-responsive-1-1">
                        <Picture src={featuredImage.node.sourceUrl} alt="" sources={[
                          {
                            srcSet: featuredImage.node.srcSet,
                            sizes: featuredImage.node.sizes,
                            mimeType: featuredImage.node.mimeType,
                          }
                        ]} />
                      </div>
                    )
                  )}
                  animatedimage={animatedImage?.sourceUrl}
                  name={decode(title)}
                  description={decode(locale === "en" ? enDescription || excerpt : excerpt)}
                  position={decode(locale === "en" ? enPosition || position : position)}
                />
              </Animated>
            );
          }
          )}
      </Grid>
    </section>
  </>;
}
